import React from 'react';
import clsx from 'clsx';

const InputLabel = ({ children, className, noBottomMargin, ...delegated }) => {
  return (
    <label className={clsx(className, 'block font-medium', { 'mb-2': !noBottomMargin })} {...delegated}>
      {children}
    </label>
  );
};

export default InputLabel;
