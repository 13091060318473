/* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid';

export default function Alert({ children, type }) {
  let colors = {
    bg: 'bg-green-50',
    text: 'text-green-800',
  };

  if (type === 'danger') {
    colors = {
      bg: 'bg-red-50',
      text: 'text-red-800',
    };
  }

  return (
    <div className={`p-4 rounded-md ${colors.bg}`}>
      <div className='flex'>
        <p className={`text-sm font-medium ${colors.text}`}>{children}</p>
      </div>
    </div>
  );
}
