import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';

import { auth } from '../firebase-client';
import toast from 'react-hot-toast';

export default function useFirebaseLogin() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const login = async (email, password) => {
    setLoading(true);
    setError(null);

    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error(error.message);
      let message = '';
      if (error.code === 'auth/user-not-found') {
        message = 'User not found';
      } else if (error.code === 'auth/wrong-password') {
        message = 'Wrong password';
      } else {
        message = 'Something went wrong. Please try again later.';
      }

      toast.error(message);
      setError(message);
    } finally {
      setLoading(false);
    }
  };

  return { login, loading, error };
}
