import { FormProvider } from 'react-hook-form';

const Form = ({ children, form: { handleSubmit, ...form }, onSubmit, ...props }) => {
  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} {...props}>
        {children}
      </form>
    </FormProvider>
  );
};

export default Form;
